import { differenceInCalendarDays, isBefore } from "date-fns";

export function tabProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const calcularValorAPagar = (
  pagamentoAtualizado,
  pagamentosList = [],
  conta
) => {
  const pagamentosReais = pagamentosList.filter(
    (pago) => parseFloat(pago?.valor) > 0
  );
  const totalPago = pagamentosReais.reduce((acumulador, objetoAtual) => {
    return acumulador + parseFloat(objetoAtual?.valor ?? 0);
  }, 0);
  const totalMultaJuros = pagamentosReais.reduce((acumulador, objetoAtual) => {
    return (
      acumulador +
      parseFloat(objetoAtual?.multa ?? 0) +
      parseFloat(objetoAtual?.juros ?? 0)
    );
  }, 0);
  const totalDescontos = pagamentosReais.reduce((acumulador, objetoAtual) => {
    return acumulador + parseFloat(objetoAtual?.desconto ?? 0);
  }, 0);
  return (
    parseFloat(conta?.valor ?? 0) +
    parseFloat(conta?.acrescimo ?? 0) -
    parseFloat(conta?.desconto ?? 0) +
    parseFloat(pagamentoAtualizado?.multa ?? 0) +
    parseFloat(pagamentoAtualizado?.juros ?? 0) -
    parseFloat(pagamentoAtualizado?.desconto ?? 0) -
    totalDescontos +
    totalMultaJuros -
    totalPago
  );
};

export const converterValorParaPorcentagem = (
  valor = 0,
  conta,
  propriedade,
  pagamento
) => {
  let porcentagemCalculada = (valor / conta?.saldoReceber) * 100;
  if (propriedade === "juros") {
    const atraso = getAtrasoDias(
      conta?.dataVencimento,
      pagamento?.dataPagamento
    );
    if (atraso > 0) {
      porcentagemCalculada = (valor / atraso / conta?.saldoReceber) * 100;
    }
  }
  return parseFloat(porcentagemCalculada.toFixed(2));
};

function calculaFloor(valor, casasDecimais = 1) {
  return (
    Math.floor(valor * Math.pow(10, casasDecimais)) /
    Math.pow(10, casasDecimais)
  );
}

export const converterPorcentagemParaValor = (
  porcentagem = 0,
  conta,
  propriedade,
  pagamento
) => {
  let valorCalculado = (porcentagem / 100) * conta?.saldoReceber;
  if (propriedade === "jurosPorcentagem") {
    const atraso = getAtrasoDias(
      conta?.dataVencimento,
      pagamento?.dataPagamento
    );
    if (atraso > 0) {
      valorCalculado *= atraso;
    }
  }
  return calculaFloor(valorCalculado, 2);
};

export function calcularValorTotal(contaReceberObjeto) {
  const acrescimoFloat = contaReceberObjeto.acrescimo
    ? parseFloat(contaReceberObjeto.acrescimo)
    : 0;
  const descontoFloat = contaReceberObjeto.desconto
    ? parseFloat(contaReceberObjeto.desconto)
    : 0;
  const total =
    parseFloat(contaReceberObjeto.valor) + acrescimoFloat - descontoFloat;

  if (total > 0) {
    return total;
  }
  return 0;
}

export function totalPagamentos(pagamentos) {
  if (pagamentos?.length) {
    return pagamentos.reduce(
      (prevVal, elem) => prevVal + parseFloat(elem.valor),
      0
    );
  }
}

export function totalDespesas(pagamentos) {
  let totalizadores = {
    totalDescontos: 0,
    totalJuros: 0,
    totalMulta: 0,
    total: 0,
  };
  if (pagamentos?.length) {
    return pagamentos.reduce((acc, elem) => {
      acc.totalDescontos += elem.desconto || 0;
      acc.totalJuros += elem.juros || 0;
      acc.totalMulta += elem.multa || 0;
      acc.total += elem.valor || 0;

      return acc;
    }, totalizadores);
  } else {
    return totalizadores;
  }
}

export function getAtrasoDias(dataVencimento, dataPagamento) {
  const date1 = dataPagamento ? new Date(dataPagamento) : new Date();
  const date2 = dataVencimento ? new Date(dataVencimento) : new Date();
  const diferencaDias = differenceInCalendarDays(date1, date2);
  return diferencaDias <= -1 || isBefore(date1, date2) ? 0 : diferencaDias;
}

export function calcularValorTotalAtualizado(data) {
  if (!data) return;
  const pagamentosReais = data.pagamentos.filter(
    (pago) => parseFloat(pago.valor) > 0
  );
  const totalPago = pagamentosReais.reduce((acumulador, objetoAtual) => {
    return acumulador + parseFloat(objetoAtual.valor ?? 0);
  }, 0);
  const totalMultaJuros = pagamentosReais.reduce((acumulador, objetoAtual) => {
    return (
      acumulador +
      parseFloat(objetoAtual.multa ?? 0) +
      parseFloat(objetoAtual.juros ?? 0)
    );
  }, 0);
  const totalDescontos = pagamentosReais.reduce((acumulador, objetoAtual) => {
    return acumulador + parseFloat(objetoAtual.desconto ?? 0);
  }, 0);
  return (
    parseFloat(data.valor) +
    parseFloat(data.acrescimo ?? 0) -
    parseFloat(data.desconto ?? 0) +
    totalMultaJuros -
    totalDescontos -
    totalPago
  );
}
