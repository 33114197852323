import { addDays, addMonths } from "date-fns";

export function tabProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export function verificarItemMaisCaro(lista) {
  return lista.reduce((maxItem, currentItem) =>
    parseFloat(currentItem.subtotal) > parseFloat(maxItem.subtotal)
      ? currentItem
      : maxItem
  );
}

function calculaFloor(valor, casasDecimais = 1) {
  return (
    Math.floor(valor * Math.pow(10, casasDecimais)) /
    Math.pow(10, casasDecimais)
  );
}

export function formatProdutosTotalizadores(
  name,
  nfEntradaItens,
  valueInputReal,
  totalNota
) {
  const valueInput = parseFloat(valueInputReal || 0);
  let listaAtualizada = nfEntradaItens.map((item) => {
    const valorProporcional =
      calculaFloor((subtotalItem(item) * 100) / totalNota) / 100;
    return {
      ...item,
      [name]: valueInput > 0 ? calculaFloor(valorProporcional * valueInput) : 0,
    };
  });
  ajustarDiferenca(name, valueInput, listaAtualizada);
  return listaAtualizada;
}

export function calculoDesconto(
  itens,
  gerarAcrescimos,
  porCemParaDecimal = true
) {
  const total =
    calculaSubtotalProdutos(itens) +
    parseFloat(gerarAcrescimos?.acrescimo ?? 0) +
    parseFloat(gerarAcrescimos?.despesas ?? 0);

  let resultado;
  if (porCemParaDecimal) {
    const descontoPorcentagem = parseFloat(
      gerarAcrescimos?.subtotalDescontoPorcentagem ?? 0
    );
    resultado = (descontoPorcentagem / 100) * total;
    resultado = calculaFloor(resultado, 2);
  } else {
    const subtotalDesconto = parseFloat(gerarAcrescimos?.subtotalDesconto ?? 0);
    resultado = (subtotalDesconto / (Math.floor(total * 10) / 10)) * 100;
    resultado = calculaFloor(resultado, 2);
  }
  return resultado;
}

export function calculaSubtotalProdutos(lista) {
  if (lista?.length > 0) {
    return lista.reduce((acumulador, objetoAtual) => {
      return acumulador + parseFloat(objetoAtual.subtotal);
    }, 0);
  }
  return 0;
}

export function calculaSubtotalTributos(tributos) {
  if (!tributos) return 0;
  const tributosChaves = Object.keys(tributos);
  if (tributosChaves.length) {
    return tributosChaves
      .filter((chave) => chave.startsWith("valor"))
      .reduce(
        (acumulador, chave) => acumulador + parseFloat(tributos[chave] ?? 0),
        0
      );
  } else {
    return 0;
  }
}

export function calculaSubtotalPagamentos(nfEntradaPagamentos) {
  if (nfEntradaPagamentos?.length > 0) {
    const total = nfEntradaPagamentos.reduce(
      (prevVal, elem) => prevVal + parseFloat(elem.valorTitulo),
      0
    );
    return total;
  }
  return 0;
}

export function totalCompra(
  nfEntrada,
  nfEntradaItens,
  nfEntradaTributos,
  calculoTotalizadores
) {
  const freteNota =
    nfEntrada?.freteCompoeNota === true ? parseFloat(nfEntrada?.frete ?? 0) : 0;
  const subtotalProdutos = calculaSubtotalProdutos(nfEntradaItens);
  const subtotalDesconto = calculaSubtotalParaChave(
    nfEntradaItens,
    calculoTotalizadores,
    "desconto"
  );
  const subtotalAcrescimo = calculaSubtotalParaChave(
    nfEntradaItens,
    calculoTotalizadores,
    "acrescimo"
  );
  const subtotalDespesas = calculaSubtotalParaChave(
    nfEntradaItens,
    calculoTotalizadores,
    "despesas"
  );
  const totalCompra =
    subtotalProdutos -
    subtotalDesconto +
    subtotalAcrescimo +
    subtotalDespesas +
    (parseFloat(nfEntradaTributos?.valorIpi) || 0) +
    (parseFloat(nfEntradaTributos?.valorIcmsSt) || 0) +
    freteNota;
  return parseFloat(totalCompra.toFixed(2));
}

export function calculaTotal(item) {
  return (
    parseFloat(item.valor ?? item.precoCompra) *
      parseFloat(item?.quantidade ?? 0) -
    parseFloat(item?.desconto ?? 0) +
    parseFloat(item?.acrescimo ?? 0) +
    parseFloat(item?.despesas ?? 0)
  );
}

export function parcelaData(indice, data, intervaloDias) {
  const dias = indice * (intervaloDias ?? 1);
  const meses = Math.floor(dias / 30);
  const diasRestantes = dias % 30;
  const dataAtualizada = addMonths(new Date(data), meses);
  const dataFinal = addDays(dataAtualizada, diasRestantes);
  return dataFinal;
}

export const converterPorcentagemValor = (valor = 0, totalNota, propriedade) =>
  propriedade.endsWith("Porcentagem")
    ? (parseFloat(valor) / 100) * parseFloat(totalNota || 0)
    : (parseFloat(valor) / parseFloat(totalNota || 0)) * 100;

export function subtotalItem(item) {
  return parseFloat(
    item.subtotal || item.quantidade * (item.valorUnitario || item.valor)
  );
}

function ajustarDiferenca(key, valorTotal, listaAtualizada) {
  const subtotal = calculaSubtotalParaChave(listaAtualizada, "", key);
  if (valorTotal !== subtotal) {
    const indiceItemMaisCaro = getIndexItemMaisCaro(listaAtualizada);
    const diferenca = valorTotal - subtotal;
    listaAtualizada[indiceItemMaisCaro][key] += diferenca;
  }
}

export function calculaSubtotalParaChave(lista, tipoOperacao, chave) {
  if (lista?.length > 0) {
    const valorCalculado = lista.reduce((acumulador, objetoAtual) => {
      return acumulador + parseFloat(objetoAtual[chave] ?? 0);
    }, 0);
    return tipoOperacao !== chave ? valorCalculado : 0;
  }
}

export function getIndexItemMaisCaro(lista) {
  return lista.reduce(
    (maxIndex, currentItem, currentIndex, array) =>
      subtotalItem(currentItem) > subtotalItem(array[maxIndex])
        ? currentIndex
        : maxIndex,
    0
  );
}
