import {
  Grid,
  CurrencyInput,
  Typography,
  TextFielDecimalNumbers,
} from "../../../../components/";
import { formatPrice, unformatPrice } from "../../../../utils";
import { calculaFloor } from "../addPedidos";

const ResumoTotal = ({
  abaValue,
  pedido,
  setPedido,
  listaPedidosProdutos,
  totalItensLista,
  mediaQuery,
  handleInputChange,
  pedidoEntity,
}) => {
  const handleInputDesconto = (event) => {
    const propriedade = event.target.name;
    const valor = unformatPrice(event.target.value);
    pedidoEntity.setHandleChangeInput(
      propriedade,
      valor ? parseFloat(valor) : 0
    );
    if (
      parseFloat((pedido[propriedade] || 0).toFixed(2)) !==
      calculaFloor(parseFloat(valor), 2)
    ) {
      if (propriedade === "desconto") {
        pedidoEntity.setDescontoPorcentagem(listaPedidosProdutos, true);
        setPedido(pedidoEntity);
      } else {
        pedidoEntity.setDescontoPorcentagem(listaPedidosProdutos);
        setPedido(pedidoEntity);
      }
    }
  };

  const nextFocusInput = (event) => {
    if (event.keyCode === 13) {
      const { form } = event.target;
      const arrayInput = Array.from(form.elements).filter(
        (element) => element.tagName == "INPUT"
      );
      const index = arrayInput.indexOf(event.target);
      let element = arrayInput[index + 1];
      if (element?.disabled) {
        element = arrayInput[index + 2];
      }
      if (element) {
        element.focus();
      } else {
        arrayInput[index].blur();
      }
      event.preventDefault();
    }
  };

  return (
    <form>
      <Grid container spacing={2} justifyContent="center" className="mx-3">
        <Grid
          item
          xs={9}
          md={2}
          className="d-flex align-items-center justify-content-end"
        >
          <span className="me-2">Subtotal:</span>
          <span className="badge bg-primary bg-gradient bg-gradient text-wrap p-2">
            <Typography color="white" style={{ fontWeight: 700 }}>
              {formatPrice(totalItensLista(listaPedidosProdutos)) || "R$ 0,00"}
            </Typography>
          </span>
        </Grid>
        <Grid
          item
          xs={9}
          md={4}
          className="d-flex align-items-center justify-content-end"
        >
          <span className="me-2">Desconto:</span>
          <TextFielDecimalNumbers
            id="descontoPorcentagem"
            name="descontoPorcentagem"
            disabled={
              pedido.status === "CANCELADO" ||
              pedido.status === "BAIXADO" ||
              abaValue !== 0
            }
            variant="outlined"
            margin="none"
            size="small"
            value={pedido?.descontoPorcentagem ?? 0}
            onBlur={handleInputDesconto}
            onKeyDown={nextFocusInput}
          />
          <CurrencyInput
            id="desconto"
            name="desconto"
            disabled={
              pedido.status === "CANCELADO" ||
              pedido.status === "BAIXADO" ||
              abaValue !== 0
            }
            className="px-1"
            variant="outlined"
            margin="none"
            size="small"
            value={pedido?.desconto ?? 0}
            onBlur={handleInputDesconto}
            onKeyDown={nextFocusInput}
          />
        </Grid>
        <Grid
          item
          xs={9}
          md={2}
          className="d-flex align-items-center justify-content-end"
        >
          <span className="me-2">Acréscimo:</span>
          <CurrencyInput
            id="acrescimo"
            name="acrescimo"
            disabled={
              pedido.status === "CANCELADO" ||
              pedido.status === "BAIXADO" ||
              abaValue !== 0
            }
            variant="outlined"
            margin="none"
            size="small"
            value={pedido?.acrescimo ?? 0}
            onBlur={handleInputChange}
            onKeyDown={nextFocusInput}
          />
        </Grid>
        <Grid
          item
          xs={9}
          md={2}
          className="d-flex align-items-center justify-content-end"
        >
          <span className="me-2">Despesas:</span>
          <CurrencyInput
            id="despesas"
            name="despesas"
            disabled={
              pedido.status === "CANCELADO" ||
              pedido.status === "BAIXADO" ||
              abaValue !== 0
            }
            variant="outlined"
            margin="none"
            value={pedido?.despesas ?? 0}
            size="small"
            onBlur={handleInputChange}
            onKeyDown={nextFocusInput}
          />
        </Grid>
        <Grid
          item
          xs={9}
          md={2}
          className={`d-flex align-items-center ${
            mediaQuery && "justify-content-end"
          }`}
        >
          <span className="me-2">Total:</span>
          <span className="badge bg-primary bg-gradient bg-gradient text-wrap p-2">
            <Typography color="white" style={{ fontWeight: 700 }}>
              {formatPrice(pedidoEntity?.totalPedido || 0)}
            </Typography>
          </span>
        </Grid>
      </Grid>
    </form>
  );
};
export default ResumoTotal;
