import shortid from "shortid";
import { notification } from "../../services";
import { CadastrarEnderecoFornecedorXml } from "./entities";

const meioPagamentoEnum = {
  "01": "fpDinheiro",
  "02": "fpCheque",
  "03": "fpCartaoCredito",
  "04": "fpCartaoDebito",
  "05": "fpCreditoLoja",
  10: "fpValeAlimentacao",
  11: "fpValeRefeicao",
  12: "fpValePresente",
  13: "fpValeCombustivel",
  14: "fpDuplicataMercantil",
  15: "fpBoletoBancario",
  16: "fpDepositoBancario",
  17: "fpPagamentoInstantaneoPIX",
  18: "fpTransferenciabancaria",
  19: "fpProgramadefidelidade",
  90: "fpSemPagamento",
  99: "fpOutro",
};

export function enderecoFornecedor(endereco) {
  const enderecoFornecedor = new CadastrarEnderecoFornecedorXml();
  enderecoFornecedor.setEnderecoCadastrado(endereco);
  return enderecoFornecedor;
}

export function parseXml(content) {
  const parser = new DOMParser();
  return parser.parseFromString(content, "application/xml");
}

export function convertXmlToJson(xml) {
  const obj = {};
  if (xml.nodeType === 1) {
    if (xml.attributes.length > 0) {
      for (let j = 0; j < xml.attributes.length; j++) {
        const attribute = xml.attributes.item(j);
        obj[attribute.nodeName] = attribute.nodeValue;
      }
    }
  }
  if (xml.hasChildNodes()) {
    for (let i = 0; i < xml.childNodes.length; i++) {
      const item = xml.childNodes.item(i);
      const nodeName = item.nodeName;
      if (typeof obj[nodeName] === "undefined") {
        obj[nodeName] =
          item.childNodes?.length && item.childNodes[0].nodeName === "#text"
            ? item.childNodes[0].nodeValue
            : convertXmlToJson(item);
      } else {
        if (typeof obj[nodeName].push === "undefined") {
          const old = obj[nodeName];
          obj[nodeName] = [];
          obj[nodeName].push(old);
        }
        obj[nodeName].push(convertXmlToJson(item));
      }
    }
  }
  return obj;
}

export function formatImpostos(impostos) {
  return Object.keys(impostos).reduce((impostosFormatados, imposto) => {
    const subImposto =
      typeof impostos[imposto] === "object"
        ? Object.entries(impostos[imposto]).reduce(
            (acc, [chave, valor]) => ({
              ...acc,
              ...(typeof valor === "object" ? valor : { [chave]: valor }),
            }),
            {}
          )
        : impostos[imposto];
    return { ...impostosFormatados, [imposto]: subImposto };
  }, {});
}

function getEnumPagamento(codigo) {
  return meioPagamentoEnum[codigo] ? meioPagamentoEnum[codigo] : codigo;
}

export function getPagamentoDuplicataXml(pagamentos, duplicatas, dataEmissao) {
  if (!pagamentos?.length && !duplicatas?.length) {
    return [];
  }
  if (pagamentos?.length != duplicatas?.length) {
    if (duplicatas?.length > pagamentos?.length) {
      return duplicatas.map((duplicata) => ({
        ...duplicata,
        tPag: getEnumPagamento(pagamentos[0].tPag),
      }));
    } else {
      return pagamentos.map((pagamento, index) => {
        if (index === 0) {
          return {
            nDup: Math.floor(Math.random() * 200),
            dVenc: dataEmissao,
            vDup: pagamento.vPag,
            tPag: getEnumPagamento(pagamento.tPag),
          };
        } else {
          const duplicataEncontrada = duplicatas.find(
            (_, indexDuplicata) => indexDuplicata === index - 1
          );
          return {
            ...duplicataEncontrada,
            tPag: getEnumPagamento(pagamento.tPag),
          };
        }
      });
    }
  } else {
    return duplicatas.map((duplicata, index) => ({
      ...duplicata,
      tPag: getEnumPagamento(pagamentos[index].tPag),
    }));
  }
}

export function extrairDadosNFe(xmlData, funcaoResetState) {
  const {
    nfeProc: {
      NFe,
      protNFe: { infProt },
    },
  } = convertXmlToJson(xmlData);
  if (!NFe) {
    funcaoResetState?.();
    return notification.alertaGenericos(
      "Por favor, inserir XML referente a NF-e"
    );
  }
  const objetoFormatDto = {
    fornecedor: NFe.infNFe?.emit,
    identificacao: NFe.infNFe?.ide,
    protocolo: infProt,
    transporte: NFe.infNFe?.transp,
    itens: Array.isArray(NFe.infNFe?.det)
      ? NFe.infNFe?.det.map((item) => {
          const imposto = formatImpostos(item.imposto);
          const itemFormatado = {
            ...item,
            imposto,
          };
          return itemFormatado;
        })
      : [
          {
            ...NFe.infNFe.det,
            imposto: formatImpostos(NFe.infNFe.det?.imposto),
          },
        ],
    totais: NFe.infNFe.total.ICMSTot,
    pagamentos: NFe.infNFe?.pag.detPag,
    duplicatas: NFe.infNFe?.cobr?.dup,
    informacoes: NFe.infNFe?.infAdic,
  };
  if (NFe.infNFe?.pag.detPag) {
    if (!Array.isArray(NFe.infNFe?.pag.detPag)) {
      objetoFormatDto.pagamentos = [NFe.infNFe?.pag.detPag];
    }
  }
  if (NFe.infNFe?.cobr?.dup) {
    if (!Array.isArray(NFe.infNFe?.cobr?.dup)) {
      objetoFormatDto.duplicatas = [NFe.infNFe?.cobr?.dup];
    }
  }
  return objetoFormatDto;
}

export function cadastroProdutosDto(produto, index) {
  const cadastroProduto = {
    ...produto,
    id: produto?.id || shortid.generate(),
    ordem: index + 1,
    fornecedores: [
      {
        id: produto.fornecedorId,
        codigoProduto: produto.codigoProduto,
        quantidadeEmbalagem: produto.quantidadeEmbalagem,
      },
    ],
    origemMercadoriaId: produto.origemMercadoriaId,
    ncmId: produto.ncmId,
    ncmCodigo: produto.ncmCodigo,
    unidadeComercialCompraId: produto.unidadeComercialCompraId,
    unidadeComercialVendaId: produto.unidadeComercialCompraId,
  };
  if (produto?.produtoId) {
    cadastroProduto.produtoId = produto.produtoId;
  }
  return cadastroProduto;
}

export function encontrarProduto(item, listaProdutos) {
  return listaProdutos?.find(
    (produto) =>
      produto?.descricao.includes(item.descricao) &&
      (produto?.codigoBarra === item.codigoBarra ||
        produto?.codigoProduto === item.codigoProduto)
  );
}

export function ordenarPorDescricao(array) {
  if (!array?.length) return array;
  return array.sort((a, b) => a?.descricao.localeCompare(b?.descricao));
}

export function erroCelulaGridProdutos(params, inputErros) {
  if (!inputErros || !params) return "";
  const indiceItem = params.api.getRowIndexRelativeToVisibleRows(params.row.id);
  return inputErros.get(`[${indiceItem}].${params.field}`)
    ? "bg-danger bg-opacity-25"
    : "";
}
