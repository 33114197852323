import { useState, useEffect, createRef } from "react";
import {
  Grid,
  Paper,
  Tabs,
  Tab,
  Button,
  TextField,
  IconButton,
  TabPanel,
  ImageCropper,
} from "../../../components";
import { AddLayout } from "../../../layouts";
import {
  UsuarioService,
  SessionService,
  EmpresaService,
  EnderecoService,
  notification,
  StorageService,
  CadastrosService,
} from "../../../services";
import { dataURLtoFile, responseErros } from "../../../utils";
import {
  CadastroBasico,
  CadastroEndereco,
  CadastroContabil,
  CadastroNfNfc,
} from "./components";
import empresaValidator from "./middlewares/addEmpresa.validator";
import CertificadoDigitalDialog from "./components/certificado-digital-dialog";
import { InputErros, PermisoesHelper, StorageHelper } from "../../../helpers";
import "./empresa.css";
import { Empresa } from "./enitites";
import { tabProps } from "./addEmpresa";
const notFoundImage =
  "https://public.siaflite.com.br/not-found.png";

const AddEmpresa = () => {
  const imageUpload = createRef();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [openEnviarCertificadoDialog, setOpenEnviarCertificadoDialog] =
    useState(false);
  const [empresa, setEmpresa] = useState((prevState) => ({
    ...prevState,
    inscricaoEstadualIsento: false,
    contadorAutorizarDadosXmlNfe: false,
    contadorAutorizarDownloadXmlNfe: false,
    suspenderAtividade: 1,
    imagemLogo: null,
    naturezaSocial: "juridica",
  }));
  const [ufid, setUfid] = useState(null);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [abaValue, setAbaValue] = useState(0);
  const [imageOverlayActive, setImageOverlayActive] = useState(false);
  const [isCropping, setIsCropping] = useState(false);
  const [tempImageLogo, setTempImageLogo] = useState(null);
  const [imagelogo, setImageLogo] = useState({});
  const [inputErro, setInputErro] = useState([]);
  const [aspectRatio, setAspectRatio] = useState(0);
  const storageService = new StorageService();
  const sessionService = new SessionService();
  const usuarioService = new UsuarioService();
  const empresaService = new EmpresaService();
  const enderecoService = new EnderecoService();
  const cadastroService = new CadastrosService();
  const storageHelper = new StorageHelper();
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("empresas-visualizar");
  const { id } = sessionService.getEmpresa();
  const inputErros = new InputErros(inputErro, setInputErro);

  useEffect(() => {
    buscaEstados();
    buscaEmpresa();
    buscaUsuario();
  }, []);

  const buscaUsuario = async () => {
    const usuario = sessionService.getUsuario();
    const data = await usuarioService.getById(usuario.id);
    setUser(data);
  };

  const buscaEmpresa = async () => {
    if (!id) return;
    setLoading(true);
    const result = await empresaService.get();
    if (!result.isAxiosError) {
      if (result.data) {
        setEmpresa(result.data);
        setImageLogo({
          originalname: result.data?.imagemLogo,
          fileName: result.data?.imagemLogoUri ?? null,
          fileData: null,
        });
        buscarCidadeById(result.data?.cidadeId);
      }
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarCidadeById = async (cidadeId) => {
    enderecoService.getCidadeById(cidadeId).then((result) => {
      if (!result.isAxiosError) {
        setUfid(result.data?.estado.id);
        buscarCidades(result.data?.estado.id);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarCidades = async (ufId) => {
    enderecoService.getCidades(ufId).then((result) => {
      if (!result.isAxiosError) {
        setCidades(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscaEstados = async () => {
    const result = await enderecoService.getEstados();
    if (!result.isAxiosError) {
      setEstados(result.data);
    } else {
      responseErros(result);
    }
  };

  const handleLogoChange = (e) => {
    let file;
    if (e.dataTransfer) {
      file = e.dataTransfer.files[0];
    } else if (e.target) {
      file = e.target.files[0];
    }
    const reader = new FileReader();
    reader.onload = function () {
      setTempImageLogo(reader.result);
      setIsCropping(true);
      setImageLogo((data) => ({ ...data, originalname: file.name }));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveLogo = () => {
    setImageLogo({ fileName: "", fileData: null });
    setEmpresa((prevState) => ({ ...prevState, imagemLogo: null }));
  };

  const handleAbaChange = (e, value) => {
    setAbaValue(value);
  };

  const handleInputChange = (e) => {
    const empresaTemp = {
      ...empresa,
      [e.target.name]:
        e.target.type == "number" ? Number(e.target.value) : e.target.value,
    };
    setEmpresa(empresaTemp);
  };

  const handleIeIsento = (e) => {
    e.persist();
    setEmpresa((prevState) => ({
      ...prevState,
      inscricaoEstadualIsento: !prevState.inscricaoEstadualIsento,
    }));
  };

  const handleCompletarEndereco = async (e) => {
    e.preventDefault();
    const { data } = await enderecoService.getByCep(empresa?.enderecoCep);
    if (!data) {
      return notification.alertaGenericos("CEP não encontrado.");
    }
    setUfid(data?.estado?.id);
    changeCidadesByEstadoId(data?.estado?.id);
    setEmpresa(() => ({
      ...empresa,
      cidadeId: data?.id,
      enderecoBairro: data?.bairro,
      enderecoLogradouro: data?.logradouro,
    }));
  };

  const handleEstadoUfInput = (id) => {
    setUfid(id);
    changeCidadesByEstadoId(id);
  };

  const changeCidadesByEstadoId = async (id) => {
    if (id) {
      enderecoService.getCidades(id).then((response) => {
        if (!response.data) {
          notification.erroValidacao("Preencha um estado");
        }
        setCidades(response?.data);
      });
    }
  };

  const handleAutocompleteInput = (nome, valor) => {
    setEmpresa((prevState) => ({
      ...prevState,
      [nome]: valor ? valor.id : undefined,
    }));
  };

  const handleCheckBox = (name, event) => {
    event.persist();
    setEmpresa((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const updateEmpresaStorage = () => {
    const dadosEmpresaStorage = storageHelper.getLocal("dados");
    if (dadosEmpresaStorage) {
      const parsedDadosEmpresaStorage = JSON.parse(dadosEmpresaStorage);
      const updatedEmpresaStorage = {
        ...parsedDadosEmpresaStorage,
        empresa: {
          ...parsedDadosEmpresaStorage.empresa,
          cnpj: empresa.cnpjCpf,
          razaoSocial: empresa.razaoSocial,
        },
      };
      storageHelper.setLocal("dados", JSON.stringify(updatedEmpresaStorage));
    }
  };

  const validarContadorCadastrado = async (empresa) => {
    const contadorCnpjCpf = empresa.contadorCnpj || empresa.contadorCpf;
    const res = await cadastroService.validarContadorCadastrado(
      contadorCnpjCpf
    );
    if (!res.isAxiosError && res.data?.id) {
      empresa.setContadorId(res.data.id);
      return;
    } else {
      throw new Error(
        `Contador não cadastrado. Faça o cadastro para vincular contador e atualizar empresa.`
      );
    }
  };

  const handleSubmit = async () => {
    const empresaDto = new Empresa(empresa);
    empresaValidator
      .validate(empresaDto, { abortEarly: false })
      .then(async () => {
        setLoading(true);
        if (empresa.contadorAutorizarDownloadXmlNfe) {
          try {
            await validarContadorCadastrado(empresaDto);
          } catch (error) {
            setLoading(false);
            return notification.alertaGenericos(error.message);
          }
        }
        if (imagelogo) await adicionarImagemEmpresa(empresaDto);
        if (empresa?.id) {
          const res = await empresaService.atualizar(empresa?.id, empresaDto);
          if (!res.isAxiosError) {
            updateEmpresaStorage();
            notification.alteracaoSucesso();
          } else {
            responseErros(res);
          }
          setLoading(false);
        } else {
          const res = await empresaService.cadastrar(empresaDto);
          if (!res.isAxiosError) {
            notification.cadastroSucesso();
          } else {
            responseErros(res);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  const adicionarImagemEmpresa = async (empresa) => {
    const formData = new FormData();
    formData.append("image", imagelogo.fileData);
    const resStorage = await storageService.addImage(formData);
    if (!resStorage.isAxiosError) {
      empresa.setImagemLogo(resStorage.data.fileName);
    }
  };

  const aspectLogo = (event) => {
    setAspectRatio(event.target.naturalWidth / event.target.naturalHeight);
  };

  return (
    <>
      <AddLayout
        id={empresa.id}
        title="Editar empresa"
        actions={
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Button
                variant="outlined"
                value={empresa?.razaoSocial ? empresa.razaoSocial : ""}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                startIcon={
                  <i
                    className="ph-fill ph-cloud-arrow-up"
                    style={{ fontSize: 18, color: "#494c62" }}
                  ></i>
                }
                type="submit"
                fullWidth
                onClick={() => {
                  setOpenEnviarCertificadoDialog(true);
                }}
              >
                Enviar Certificado
              </Button>
            </Grid>
          </Grid>
        }
        showUpdate={permissoesHelper.temPermisao("empresas-editar")}
        onClickSalvar={handleSubmit}
        loading={loading}
      >
        <Grid className="gridContainer">
          <Grid container spacing={1}>
            <Grid item xs={12} className="inputGridTop">
              <TextField
                id="fantasia"
                name="fantasia"
                label="Nome Fantasia"
                variant="outlined"
                margin="normal"
                value={empresa?.fantasia ? empresa.fantasia : ""}
                onChange={(e) => {
                  setInputErro([]);
                  handleInputChange(e);
                }}
                fullWidth
                required
                error={inputErros.get("fantasia")}
              />
            </Grid>
            <Grid item xs={12} className="inputGridBottom">
              <TextField
                id="razao-social"
                name="razaoSocial"
                label="Razão Social"
                variant="outlined"
                margin="normal"
                value={empresa?.razaoSocial ? empresa.razaoSocial : ""}
                onChange={(e) => {
                  setInputErro([]);
                  handleInputChange(e);
                }}
                fullWidth
                required
                error={inputErros.get("razaoSocial")}
              />
            </Grid>
          </Grid>
          <Grid
            className="mediaContainer"
            onMouseOver={() => setImageOverlayActive(true)}
            onMouseLeave={() => setImageOverlayActive(false)}
          >
            <img
              className="media"
              src={imagelogo?.fileName ? imagelogo.fileName : notFoundImage}
              alt="Logo Empresa"
              onLoad={aspectLogo}
              style={{
                width: aspectRatio <= 1.5 ? "250px" : "300px",
                height: aspectRatio <= 1.5 ? "185px" : "90px",
                margin: "10px 0 10px",
              }}
            />
            <input
              accept="image/*"
              className="logoImageInput"
              ref={imageUpload}
              type="file"
              onChange={handleLogoChange}
            />
            {imageOverlayActive ? (
              <div className="editImageContainer">
                <IconButton
                  size="medium"
                  aria-label="Alterar imagem"
                  onClick={() => imageUpload.current.click()}
                >
                  <i className="ph-fill ph-camera editImageIcon"></i>
                </IconButton>
                <IconButton
                  size="medium"
                  aria-label="Remover imagem"
                  onClick={handleRemoveLogo}
                >
                  <i className="ph-fill ph-trash editImageIcon"></i>
                </IconButton>
              </div>
            ) : (
              ""
            )}
            {isCropping && (
              <ImageCropper
                image={tempImageLogo}
                open={isCropping}
                onSave={(croppedFile) => {
                  setIsCropping(false);
                  setTempImageLogo(false);
                  setImageOverlayActive(false);

                  const file = dataURLtoFile(
                    croppedFile,
                    imagelogo.originalname
                  );
                  setImageLogo((data) => ({
                    ...data,
                    fileName: croppedFile,
                    fileData: file,
                  }));
                }}
                onHide={() => {
                  setIsCropping(false);
                  setTempImageLogo(false);
                  setImageOverlayActive(false);
                }}
              />
            )}
          </Grid>
        </Grid>
        <Paper sx={{ mt: 1 }}>
          <Tabs
            value={abaValue}
            onChange={handleAbaChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Básico" {...tabProps(0)} />
            <Tab label="Endereço" {...tabProps(1)} />
            <Tab label="Contábil" {...tabProps(2)} />
            <Tab label="Nf-e / Nfc-e" {...tabProps(3)} />
          </Tabs>
        </Paper>
        <TabPanel className="tabPanel" value={abaValue} index={0}>
          <CadastroBasico
            empresa={empresa}
            setEmpresa={setEmpresa}
            inputErros={inputErros}
            setUfid={setUfid}
            changeCidadesByEstadoId={changeCidadesByEstadoId}
            handleInputChange={handleInputChange}
            handleIeIsento={handleIeIsento}
            setLoading={setLoading}
          />
        </TabPanel>
        <TabPanel className="tabPanel" value={abaValue} index={1}>
          <CadastroEndereco
            empresa={empresa}
            estados={estados}
            cidades={cidades}
            ufid={ufid}
            inputErros={inputErros}
            handleInputChange={handleInputChange}
            handleCompletarEndereco={handleCompletarEndereco}
            handleEstadoUfInput={handleEstadoUfInput}
            handleAutocompleteInput={handleAutocompleteInput}
          />
        </TabPanel>
        <TabPanel className="tabPanel" value={abaValue} index={2}>
          <CadastroContabil
            user={user}
            empresa={empresa}
            handleInputChange={handleInputChange}
            handleCheckBox={handleCheckBox}
          />
        </TabPanel>
        <TabPanel className="tabPanel" value={abaValue} index={3}>
          <CadastroNfNfc
            empresa={empresa}
            setEmpresa={setEmpresa}
            inputErros={inputErros}
            handleInputChange={handleInputChange}
            handleAutocompleteInput={handleAutocompleteInput}
          />
        </TabPanel>
      </AddLayout>
      <CertificadoDigitalDialog
        empresa={empresa}
        openEnviarCertificadoDialog={openEnviarCertificadoDialog}
        setOpenEnviarCertificadoDialog={setOpenEnviarCertificadoDialog}
      />
    </>
  );
};

export default AddEmpresa;
