import { useState, useEffect, createRef, useRef, useCallback } from "react";
import { AddLayout } from "../../../layouts";
/* import { parse } from "date-fns"; */
import Webcam from "react-webcam";
import {
  TextareaAutosize,
  Menu,
  Modal,
  Autocomplete,
  Select,
  Switch,
  FormControlLabel,
  MenuItem,
  Button,
  InputAdornment,
  IconButton,
  Box,
  Grid,
  TextField,
  ImageCropper,
  MaskedInput,
  Form,
  DatePicker,
  //TextFieldNumber,
} from "../../../components";
import {
  PessoaService,
  SessionService,
  notification,
  history,
  ReceitaCnpjCpfService,
  StorageService,
  TiposPessoasService,
  ConfiguracoesService,
  EnderecoService,
  ColaboradorService,
  EtiquetasService,
} from "../../../services";
import "./pessoa.css";
import { Enderecos, TipoPessoas } from "./components";
import {
  dataURLtoFile,
  userLogs,
  responseErros,
  formatDate,
  voltar,
  cacheApi,
} from "../../../utils";
import {
  pessoaValidator,
  clienteValidator,
  fornecedorValidator,
  transportadoraValidator,
  prestadorServicoValidator,
  colaboradorValidator,
  enderecoValidator,
} from "./middlewares";
import {
  value,
  ValidarCpfCnpjHelper,
  InputErros,
  PermisoesHelper,
} from "../../../helpers";
import shortid from "shortid";
import { setInputValuePessoa } from "./addPessoa";
import { useImportContext } from "../../../contexts/import.context";
import { Pessoa } from "../entities/pessoa.entity";
//import { filtrosUrl } from "../../../helpers";
import { useCacheContext } from "../../../contexts/cache.context";
const notFoundImage =
  "https://public.siaflite.com.br/not-found.png";

const sessionService = new SessionService();
const pessoaService = new PessoaService();
const tiposPessoasService = new TiposPessoasService();
const receitaCnpjCpfService = new ReceitaCnpjCpfService();
const enderecoService = new EnderecoService();
const validarCpfCnpjHelper = new ValidarCpfCnpjHelper();
const storageService = new StorageService();
const colaboradorService = new ColaboradorService();
const configuracoesService = new ConfiguracoesService();
const etiquetasService = new EtiquetasService();

const AddPessoas = ({
  match,
  isFullscreenDialog,
  handleCloseFullscreenDialog,
}) => {
  const { id } = match.params;
  const { initialCollapses } = useImportContext();
  const { cacheDto, setCacheDto } = useCacheContext();
  const imageUpload = createRef();
  const webcamRef = useRef(null);
  const clienteFormRef = useRef(null);
  const fornecedorFormRef = useRef(null);
  const transportadoraFormRef = useRef(null);
  const colaboradorFormRef = useRef(null);
  const prestadorServicoFormRef = useRef(null);
  const [permisoes, setPermisoes] = useState({});
  const [obrigadoriedadeCnpjCpf, setObrigatoriedadeCnpjCpf] = useState(false);
  const [imageOverlayActive, setImageOverlayActive] = useState(false);
  const [pessoa, setPessoa] = useState({
    ativado: true,
    naturezaSocial: "fisica",
  });
  const prevStateRef = useRef(pessoa);
  const [contadorModificacao, setContadorModificacao] = useState(0);
  const [cliente, setCliente] = useState({
    indicadorConsumidorFinal: false,
    tipoContribuinteId: 3,
  });
  const [fornecedor, setFornecedor] = useState({});
  const [transportadora, setTransportadora] = useState({});
  const [colaborador, setColaborador] = useState({
    vendedor: false,
  });
  const [prestadorServico, setPrestadorServico] = useState({});
  const [enderecosLista, setEnderecosLista] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const [isCropping, setIsCropping] = useState(false);
  const [imageAvatar, setImageAvatar] = useState(null);
  const [listaTiposPessoa, setListaTiposPessoa] = useState([]);
  const [listaEtiquetas, setListaEtiquetas] = useState([]);
  const [userLog, setUserLog] = useState({});
  const [loading, setLoading] = useState(false);
  const [abaValue, setAbaValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openEndereco, setOpenEndereco] = useState(false);
  const [inputErroEndereco, setInputErroEndereco] = useState([]);
  const inputErrosEnderecos = new InputErros(
    inputErroEndereco,
    setInputErroEndereco
  );
  const [buscaCnpjRealizada, setBuscaCnpjRealizada] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("pessoas-visualizar");
  const pessoaValidatorService = pessoaValidator(obrigadoriedadeCnpjCpf);

  const tiposPessoasAbaAtiva = {
    fornecedor: 1,
    transportadora: 2,
    colaborador: 3,
    "prestador de serviço": 4,
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 330,
    height: 380,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };
  const videoConstraints = {
    width: 300,
    height: 300,
    facingMode: "user",
  };

  const handleCloseMenu = (event) => {
    if (event.target.value == 1) {
      imageUpload.current.click();
      setAnchorEl(null);
    } else {
      handleOpen();
      setAnchorEl(null);
    }
  };

  async function buscarDadosIniciais() {
    await buscaPessoa();
    buscaConfiguracoes();
    buscarEtiquetas();
    buscaPermisoes();
    buscaTipoPessoa();
  }

  useEffect(() => {
    buscarDadosIniciais();
  }, []);

  useEffect(() => {
    if (!prevStateRef?.current || contadorModificacao <= 1) {
      setContadorModificacao((prevCount) => prevCount + 1);
      prevStateRef.current = montaObjetoPessoa(pessoa);
    }
  }, [pessoa]);

  async function onClickLog() {
    if ("userCreatedLog" in userLog) return;
    const logs = await userLogs(pessoa);
    setUserLog(logs);
  }

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageAvatar(imageSrc);
    setIsCropping(true);
    setOpen(false);
  }, [webcamRef]);

  function buscaPermisoes() {
    const permisoes = sessionService.getPermisoes();
    if (permisoes) {
      setPermisoes(permisoes);
    }
  }

  async function buscaConfiguracoes() {
    if (id) return;
    const configuracoes = await configuracoesService.get();
    if (configuracoes.data.count) {
      setObrigatoriedadeCnpjCpf(
        configuracoes.data.rows[0].obrigatoriedadeCnpjCpf
      );
    }
  }

  const buscaTipoPessoa = async () => {
    const cacheBody = {
      timeMinutes: 60,
      apiFunction: tiposPessoasService.getAll(),
      dto: { cacheDto, setCacheDto },
    };
    const res = await cacheApi(tiposPessoasService.urlBase, cacheBody);
    if (!res.isAxiosError) {
      const filterCredenciadora = res.data.filter(
        (objeto) => objeto.descricao !== "Credenciadora"
      );
      setListaTiposPessoa(filterCredenciadora);
    }
  };

  const buscaPessoa = async () => {
    if (id) {
      setLoading(true);
      pessoaService.getById(id).then((res) => {
        if (!res.isAxiosError) {
          if (!res.data) {
            initialCollapses();
            voltar();
            return;
          }
          if (res.data.cnpjCpf === "") {
            delete res.data.cnpjCpf;
          }
          adicionarTiposPessoas(res.data);
          setPessoa(res.data);
          setInputValuePessoa(res.data);
          abaValueAtiva(res.data?.tiposPessoas[0]?.descricao.toLowerCase());
          inserirValoresListaEtiquetas(res.data?.etiquetasPessoa);
        } else {
          responseErros(res);
          initialCollapses();
          voltar();
        }
        setLoading(false);
      });
    }
  };

  function inserirValoresListaEtiquetas(listaEtiquetasIds) {
    const idsEtiquetas =
      listaEtiquetasIds?.map((etiquetas) => ({
        id: etiquetas.etiquetaId,
      })) || [];
    setPessoa((prevState) => ({
      ...prevState,
      ...pessoa,
      etiquetasPessoa: idsEtiquetas,
    }));
  }

  const abaValueAtiva = (tiposPessoas) => {
    setAbaValue(tiposPessoasAbaAtiva[tiposPessoas] || 0);
  };

  const adicionarTiposPessoas = (pessoa) => {
    const dataCliente = pessoa?.clientes?.length ? pessoa.clientes[0] : {};
    setCliente(dataCliente);
    const dataFornecedor = pessoa?.fornecedores?.length
      ? pessoa.fornecedores[0]
      : {};
    setFornecedor(dataFornecedor);
    const dataTransportadora = pessoa?.transportadoras?.length
      ? pessoa.transportadoras[0]
      : {};
    setTransportadora(dataTransportadora);
    const dataColaborador = pessoa?.colaboradores?.length
      ? pessoa.colaboradores[0]
      : {};
    setColaborador(dataColaborador);
    const dataPrestadorServico = pessoa?.prestadorSevico?.length
      ? pessoa.prestadorSevico[0]
      : {};
    setPrestadorServico(dataPrestadorServico);
  };

  const buscarEtiquetas = () => {
    const filter = {
      nonPaginated: true,
      ativado: true,
      tipo: "PESSOA",
    };
    etiquetasService.getAll(filter).then((res) => {
      if (!res.isAxiosError) {
        setListaEtiquetas(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const pessoasTemp = {
      ...pessoa,
      [event.target.name]: event.target.value || "",
    };
    if (name === "inscricaoEstadualRural" || name === "rg") {
      pessoasTemp[name] = value?.replace(/[^0-9]/g, "");
    }
    setPessoa(pessoasTemp);
  };

  const handleDateChange = (prop, date) => {
    if (!date) {
      date = "Invalid Date";
    }
    setPessoa({
      ...pessoa,
      [prop]: date != "Invalid Date" ? formatDate.toSend(date) : null,
    });
  };

  const buscarCpfCnpjRepetido = async (cnpjCpf, naturezaSocial) => {
    let data = null;
    if (id) {
      const res = await pessoaService.getAllFiltroAvancado({
        id: `~neq~${id}`,
        cnpjCpf,
        restritiva: true,
      });
      if (!res.isAxiosError) {
        data = res.data;
      }
    } else {
      const res = await pessoaService.getAllFiltroAvancado({
        cnpjCpf,
        restritiva: true,
      });
      if (!res.isAxiosError) {
        data = res.data;
      }
    }
    if (data && data.rows.length > 0) {
      const pessoa = data.rows[0];
      if (naturezaSocial == "fisica") {
        inputErros.gerar("cnpjCpf");
        throw new Error(
          `CPF já cadastrado para: ${
            pessoa.codigo
          } - ${pessoa.nomeRazaoSocial.slice(0, 24)}...`
        );
      } else {
        inputErros.gerar("cnpjCpf");
        throw new Error(
          `CNPJ já cadastrado para: ${
            pessoa.codigo
          } - ${pessoa.nomeRazaoSocial.slice(0, 24)}...`
        );
      }
    }
  };

  async function verificarDocumento(cnpjCpf, naturezaSocial) {
    const pessoaFisica = naturezaSocial == "fisica";
    const documentoValido = pessoaFisica
      ? validarCpfCnpjHelper.cpf(cnpjCpf, obrigadoriedadeCnpjCpf)
      : validarCpfCnpjHelper.cnpj(cnpjCpf, obrigadoriedadeCnpjCpf);
    if (!documentoValido) {
      inputErros.gerar("cnpjCpf");
      throw new Error(`${pessoaFisica ? "CPF" : "CNPJ"} inválido`);
    }
    return await verificarDocumentoRepetido(cnpjCpf, naturezaSocial);
  }

  async function validarUsuarioVendedor(dados) {
    const filtro = {
      nonPaginated: true,
      vendedor: true,
    };
    const { usuarioId, bancoCaixaId } = dados.colaborador;
    if (!usuarioId && !bancoCaixaId) return;
    await colaboradorService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        const colaboradorVendedorUsuario = result.data.find(
          (colaborador) => colaborador.usuarioId === usuarioId
        );
        if (
          colaboradorVendedorUsuario?.usuarioId &&
          colaboradorVendedorUsuario.pessoa.id !== Number(id)
        ) {
          inputErros.gerar("usuarioId");
          throw new Error("Usuário já vinculado a um colaborador");
        }
        const colaboradorVendedorBanco = result.data.find(
          (colaborador) => colaborador.bancoCaixaId === bancoCaixaId
        );
        if (
          colaboradorVendedorBanco?.bancoCaixaId &&
          colaboradorVendedorBanco.pessoa.id !== Number(id)
        ) {
          inputErros.gerar("bancoCaixaId");
          throw new Error("Caixa já vinculado a um colaborador");
        }
      } else {
        responseErros(result);
      }
    });
  }

  async function verificarDocumentoRepetido(cnpjCpf, naturezaSocial) {
    if (cnpjCpf) {
      await buscarCpfCnpjRepetido(cnpjCpf, naturezaSocial);
    }
  }

  async function verificarTiposPessoa(pessoaForm) {
    if (pessoaForm.tiposPessoas.length) {
      for (const tipo of pessoaForm.tiposPessoas) {
        const isTipoPessoaValid = await validateTiposPessoa(
          tipo.id,
          pessoaForm
        );
        if (!isTipoPessoaValid) {
          throw new Error(
            `Preencha o(s) campo(s) obrigatório(s) da aba ${tipo.descricao}`
          );
        }
      }
    }
  }

  const verificarImagemPessoa = async (pessoaForm) => {
    if (!imageAvatar) {
      return pessoaForm?.imagemOriginal;
    }
    if (pessoaForm.imagemOriginal) {
      await storageService.removeImage(pessoaForm.imagemOriginal);
    }
    const formData = new FormData();
    formData.append("image", imageAvatar);
    const resStorage = await storageService.addImage(formData);
    pessoaForm.avatar = !resStorage.isAxiosError
      ? resStorage.data.fileName
      : pessoaForm.avatar;
    pessoa.imagemOriginal = pessoaForm.avatar;
    return pessoa.imagemOriginal || pessoaForm.avatar;
  };

  const montaObjetoPessoa = (body) => {
    const bodyTemp = Number.isFinite(body) ? obterValoresDoFormulario() : body;
    const dadosPessoa = new Pessoa(
      bodyTemp,
      pessoa,
      cliente,
      fornecedor,
      transportadora,
      colaborador,
      prestadorServico
    );
    dadosPessoa.setCnpjCpf(id, pessoa, bodyTemp);
    dadosPessoa.setPessoaEtiquetasIds(pessoa.etiquetasPessoa);
    dadosPessoa.setInformacoesBuscaCnpj(buscaCnpjRealizada, pessoa);
    return dadosPessoa;
  };

  const handleSubmit = (body) => {
    const dadosPessoa = montaObjetoPessoa(body);
    pessoaValidatorService
      .validate(dadosPessoa, { abortEarly: false })
      .then(async () => {
        const avatar = await verificarImagemPessoa(dadosPessoa);
        dadosPessoa.setAvatar(avatar);
        try {
          await verificarTiposPessoa(dadosPessoa);
          if (pessoa.cnpjCpf !== dadosPessoa.cnpjCpf) {
            await verificarDocumento(
              dadosPessoa.cnpjCpf,
              dadosPessoa.naturezaSocial
            );
          }
          if (dadosPessoa.idTiposPessoa.includes(4)) {
            await validarUsuarioVendedor(dadosPessoa);
          }
        } catch (error) {
          return notification.alertaGenericos(error.message);
        }
        setLoading(true);
        if (id) {
          atualizarPessoa(dadosPessoa);
        } else {
          cadastrarPessoa(dadosPessoa, body);
        }
      })
      .catch((err) => {
        inputErros.set(err);
        retornoElemento(err?.inner?.length ? err?.inner[0]?.params.path : "");
      });
  };

  const validateTiposPessoa = async (tipo, body) => {
    switch (tipo) {
      case 1:
        return validate(clienteValidator, body.cliente, inputErros);
      case 2:
        return validate(fornecedorValidator, body.fornecedor, inputErros);
      case 3:
        return validate(
          transportadoraValidator,
          body.transportadora,
          inputErros,
          true
        );
      case 4:
        return validate(colaboradorValidator, body.colaborador, inputErros);
      case 5:
        return validate(
          prestadorServicoValidator,
          body.prestadorServico,
          inputErros
        );
      default:
        return true;
    }
  };

  const validateEnderecos = () => {
    if (!enderecosLista.length) {
      validate(enderecoValidator, {}, inputErrosEnderecos);
      setOpenEndereco(true);
      return false;
    }
    return true;
  };

  const validate = (shema, objeto, inputErros, validaEnderecos) => {
    if (validaEnderecos && !validateEnderecos()) return;
    return shema
      .validate(objeto, { abortEarly: false })
      .then(() => {
        return true;
      })
      .catch((err) => {
        inputErros.set(err);
        retornoElemento(err?.inner?.length ? err?.inner[0]?.params.path : "");
        return false;
      });
  };

  const cadastrarPessoa = async (body, indiceSelecionado) => {
    if (enderecosLista.length) {
      body.enderecos = enderecosLista.map((endereco) => {
        delete endereco.id;
        return endereco;
      });
    }
    const res = await pessoaService.cadastrar(body);
    setLoading(false);
    if (!res.isAxiosError) {
      notification.cadastroSucesso();
      if (!Number.isInteger(indiceSelecionado)) {
        if (isFullscreenDialog) return handleCloseFullscreenDialog();
        history.goBack();
      } else if (indiceSelecionado === 1) {
        location.reload();
      }
    } else {
      responseErros(res);
    }
  };

  const atualizarPessoa = async (body) => {
    const res = await pessoaService.atualizar(id, body);
    setLoading(false);
    if (!res.isAxiosError) {
      notification.alteracaoSucesso();
      history.goBack();
    } else {
      responseErros(res);
    }
  };

  const handleAvatarChange = (event) => {
    let file;
    if (event.dataTransfer) {
      file = event.dataTransfer.files[0];
    } else if (event.target) {
      file = event.target.files[0];
    }

    const reader = new FileReader();
    reader.onload = function () {
      setImageAvatar(reader.result);
      setIsCropping(true);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveAvatar = () => {
    setPessoa({
      ...pessoa,
      avatar: null,
    });
    setImageAvatar(null);
  };

  const onSaveImageClopper = (croppedFile) => {
    setIsCropping(false);
    setImageOverlayActive(false);
    const file = dataURLtoFile(croppedFile, "avatar");
    setImageAvatar(file);
    setPessoa({
      ...pessoa,
      avatar: croppedFile,
    });
  };

  const onHideImageCropper = () => {
    setIsCropping(false);
    setImageOverlayActive(false);
  };

  const onClickPesquisarCpfCnpj = async () => {
    const cnpjCpf = document.getElementById("cnpjCpf");
    const doc = cnpjCpf.value.replace(/\D+/g, "");
    if (!cnpjCpf.value) {
      inputErros.gerar("cnpjCpf", "Cnpj");
      return notification.alertaGenericos(`Cnpj é obrigatório para pesquisa`);
    }
    if (!pessoa?.naturezaSocial) {
      return inputErros.gerar("naturezaSocial", "Natureza Social");
    }
    try {
      await verificarDocumento(doc, pessoa.naturezaSocial);
    } catch (error) {
      return notification.alertaGenericos(error.message);
    }
    inputErros.set(null);
    const result = await receitaCnpjCpfService.buscarCnpj(doc);
    if (!result.isAxiosError) {
      setBuscaCnpjRealizada(true);
      setPessoa((prevState) => ({
        ...prevState,
        cnpjCpf: doc,
        nomeRazaoSocial: result.data?.razao_social,
        apelidoFantasia: result.data?.nome_fantasia,
        dataAbertura: formatDate.toSend(
          new Date(result.data?.data_inicio_atividade)
        ),
        email: result.data?.email,
      }));
      if (result.data?.cep) {
        const enderecoData = await enderecoService.getByCep(result.data?.cep);
        if (!enderecoData.isAxiosError) {
          setEnderecosLista([
            {
              id: shortid.generate(),
              cep: result.data?.cep,
              bairro: enderecoData.data?.bairro,
              logradouro: enderecoData.data?.logradouro,
              cidade: enderecoData.data?.nome,
              estado: enderecoData.data.estado?.sigla,
              estadoId: enderecoData.data.estado?.id,
              numero: result.data?.numero,
              descricao: result.data?.descricao_tipo_de_logradouro,
              cidadeId: enderecoData.data?.id,
            },
          ]);
        } else responseErros(enderecoData);
      }
    } else responseErros(result);
  };

  const getMultipleAutocompleteValues = (valores, lista) => {
    if (valores) {
      return valores.map((valor) => value.autoComplete(lista, valor.id));
    }
    return [];
  };

  const retornoElemento = (id) => {
    const ref = document.getElementById(id);
    if (!ref) return;
    setTimeout(
      () =>
        ref.scrollIntoView({
          behavior: "smooth",
          block: "center",
        }),
      100
    );
  };

  function obterValoresDoFormulario() {
    let formulario = document.getElementById("form");
    let elementosDoFormulario = formulario.elements;
    let valores = {};
    for (let i = 0; i < elementosDoFormulario.length; i++) {
      let elemento = elementosDoFormulario[i];
      if (elemento.type !== "submit") {
        valores[elemento.name] =
          elemento.value && elemento.value != "" ? elemento.value : undefined;
      }
    }
    return valores;
  }

  return (
    <>
      <AddLayout
        id={id}
        formSubmit
        title={pessoa?.id ? "Editar pessoa" : "Cadastrar pessoa"}
        onClickSalvar={handleSubmit}
        loading={loading}
        userLog={id ? userLog : null}
        onClickLog={onClickLog}
        isFullscreenDialog={isFullscreenDialog}
        showUpdate={permissoesHelper.temPermisao("pessoas-editar")}
        actions={
          <FormControlLabel
            control={
              <Switch
                checked={pessoa.ativado ?? false}
                onChange={() =>
                  setPessoa((prevState) => ({
                    ...prevState,
                    ativado: !pessoa?.ativado,
                  }))
                }
                inputProps={{ "aria-label": "controlled" }}
                name="ativado"
                color="primary"
              />
            }
            label={pessoa?.ativado ? "Ativado" : "Desativado"}
          />
        }
        formState={{
          inicial: prevStateRef?.current,
          final: montaObjetoPessoa(pessoa),
        }}
      >
        {(!id || Object.keys(pessoa).length > 0) && (
          <Form onSubmit={handleSubmit}>
            <Grid className="grid-container">
              <Grid container spacing={2}>
                {id && (
                  <Grid item xs={2}>
                    <TextField
                      id="codigo"
                      name="codigo"
                      label="Código"
                      disabled
                      variant="outlined"
                      value={pessoa.codigo ? pessoa.codigo : ""}
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid item xs={id ? 3 : 4}>
                  <Select
                    label="Natureza Social"
                    name="naturezaSocial"
                    id="naturezaSocial"
                    variant="outlined"
                    disabled={id ? true : false}
                    onChange={handleInputChange}
                    value={pessoa.naturezaSocial}
                    InputLabelProps={{ shrink: true }}
                    error={inputErros.get("naturezaSocial")}
                    required
                  >
                    <MenuItem value={"fisica"}>Física</MenuItem>
                    <MenuItem value={"juridica"}>Jurídica</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={id ? 7 : 8}>
                  <TextField
                    label={
                      pessoa.naturezaSocial === "juridica"
                        ? "Razão Social"
                        : "Nome"
                    }
                    name="nomeRazaoSocial"
                    id="nomeRazaoSocial"
                    variant="outlined"
                    onChange={handleInputChange}
                    fullWidth
                    obj={pessoa}
                    value={pessoa?.nomeRazaoSocial}
                    error={inputErros.get("nomeRazaoSocial")}
                    required
                  />
                </Grid>
                <Grid item xs={pessoa.naturezaSocial === "juridica" ? 8 : 4}>
                  <TextField
                    label={
                      pessoa.naturezaSocial === "juridica"
                        ? "Nome Fantasia"
                        : "Apelido"
                    }
                    name="apelidoFantasia"
                    id="apelidoFantasia"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    InputLabelProps={{ shrink: true }}
                    error={
                      pessoa.naturezaSocial === "juridica"
                        ? inputErros.get("apelidoFantasia")
                        : false
                    }
                    value={pessoa?.apelidoFantasia}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DatePicker
                    id={
                      pessoa.naturezaSocial === "juridica"
                        ? "dataAbertura"
                        : "dataNascimento"
                    }
                    label={
                      pessoa.naturezaSocial === "juridica"
                        ? "Data de Abertura"
                        : "Data de Nascimento"
                    }
                    name={
                      pessoa.naturezaSocial === "juridica"
                        ? "dataAbertura"
                        : "dataNascimento"
                    }
                    minDate={new Date(1900, 1, 1)}
                    maxDate={new Date()}
                    format="dd/MM/yyyy"
                    onChange={(date) => {
                      if (pessoa.naturezaSocial === "juridica") {
                        handleDateChange("dataAbertura", date);
                      } else {
                        handleDateChange("dataNascimento", date);
                      }
                    }}
                    value={
                      pessoa.naturezaSocial === "juridica"
                        ? pessoa.dataAbertura
                          ? pessoa.dataAbertura
                          : null
                        : pessoa.dataNascimento
                        ? pessoa.dataNascimento
                        : null
                    }
                    error={
                      pessoa.naturezaSocial === "fisica"
                        ? inputErros.get("dataNascimento")
                        : false
                    }
                  />
                </Grid>
                {pessoa.naturezaSocial !== "juridica" && (
                  <Grid item xs={4}>
                    <TextField
                      label="Inscrição Estadual Rural"
                      id="inscricaoEstadualRural"
                      name="inscricaoEstadualRural"
                      variant="outlined"
                      fullWidth
                      onChange={handleInputChange}
                      value={Number(pessoa?.inscricaoEstadualRural) || ""}
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <MaskedInput
                    id="cnpjCpf"
                    label={
                      pessoa.naturezaSocial === "juridica" ? "CNPJ" : "CPF"
                    }
                    name="cnpjCpf"
                    variant="outlined"
                    margin="none"
                    disabled={id && pessoa?.cnpjCpf ? true : false}
                    required={obrigadoriedadeCnpjCpf}
                    mask={
                      pessoa.naturezaSocial === "juridica"
                        ? "99.999.999/9999-99"
                        : "999.999.999-99"
                    }
                    value={pessoa?.cnpjCpf}
                    error={inputErros.get("cnpjCpf")}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: pessoa.naturezaSocial === "juridica" && (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={id ? true : false}
                            onClick={onClickPesquisarCpfCnpj}
                          >
                            <i
                              className="ph-bold ph-magnifying-glass"
                              style={{ color: "#494C62", fontSize: 18 }}
                            ></i>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    className={
                      pessoa.naturezaSocial === "juridica" ? "d-flex" : "d-none"
                    }
                    id="inscricaoEstadual"
                    label="Inscrição Estadual"
                    name="inscricaoEstadual"
                    variant="outlined"
                    fullWidth
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    error={inputErros.get("inscricaoEstadual")}
                  />
                  <TextField
                    className={
                      pessoa.naturezaSocial !== "juridica" ? "d-flex" : "d-none"
                    }
                    id="registroGeral"
                    label="RG"
                    name="registroGeral"
                    variant="outlined"
                    fullWidth
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    error={false}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="contato"
                    label="Contato"
                    name="contato"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="E-mail"
                    name="email"
                    id="email"
                    type="email"
                    variant="outlined"
                    error={inputErros.get("email")}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleInputChange}
                    value={pessoa?.email}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <MaskedInput
                    id="telefone"
                    label="Telefone"
                    name="telefone"
                    margin="none"
                    error={inputErros.get("telefone")}
                    mask="(99) 9999-9999"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <IconButton disabled={true}>
                          <i
                            className="ph-fill ph-phone"
                            style={{
                              color: "#494C62",
                              fontSize: 20,
                            }}
                          ></i>
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MaskedInput
                    id="celular"
                    label="Celular"
                    name="celular"
                    margin="none"
                    error={inputErros.get("celular")}
                    mask="(99) 9 9999-9999"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <IconButton disabled={true}>
                          <i
                            className="ph-fill ph-device-mobile"
                            style={{
                              color: "#494C62",
                              fontSize: 20,
                            }}
                          ></i>
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id="etiquetasPessoa"
                    name="etiquetasPessoa"
                    options={listaEtiquetas}
                    noOptionsText="Sem opções"
                    autoHighlight
                    renderOption={(props, option) => {
                      return option ? (
                        <li {...props}>
                          <div className="d-flex align-items-center gap-2 w-100">
                            <i
                              className="ph-fill ph-bookmark-simple"
                              style={{ color: option.cor, fontSize: 18 }}
                            />
                            <div>{option.descricao}</div>
                          </div>
                        </li>
                      ) : (
                        ""
                      );
                    }}
                    getOptionLabel={(option) => {
                      return option ? option.descricao : "";
                    }}
                    onChange={(_, newValue) => {
                      setPessoa({
                        ...pessoa,
                        etiquetasPessoa: newValue,
                      });
                    }}
                    value={getMultipleAutocompleteValues(
                      pessoa?.etiquetasPessoa,
                      listaEtiquetas
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Etiquetas"
                        error={inputErros.get("etiquetasPessoa")}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                className="media-container"
                onMouseOver={() => setImageOverlayActive(true)}
                onMouseLeave={() => setImageOverlayActive(false)}
              >
                <img
                  className="media"
                  src={pessoa.avatar ?? notFoundImage}
                  alt="avatar"
                />
                <input
                  accept="image/*"
                  className="logo-image-input"
                  ref={imageUpload}
                  type="file"
                  onChange={(event) => handleAvatarChange(event)}
                />
                {imageOverlayActive ? (
                  <div className="edit-image-container">
                    <IconButton
                      size="medium"
                      aria-label="Alterar imagem"
                      onClick={handleClickMenu}
                    >
                      <i
                        className={`edit-image-icon ph-fill ph-camera`}
                        style={{ color: "#efefef", fontSize: 30 }}
                      ></i>
                    </IconButton>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={handleCloseMenu}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      {[
                        <MenuItem
                          onClick={handleCloseMenu}
                          value={1}
                          key={shortid.generate()}
                        >
                          Escolher foto no computador
                        </MenuItem>,
                        <MenuItem
                          onClick={handleCloseMenu}
                          value={2}
                          key={shortid.generate()}
                        >
                          Abrir webcam
                        </MenuItem>,
                      ]}
                    </Menu>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Webcam
                          audio={false}
                          height={300}
                          width={300}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          videoConstraints={videoConstraints}
                        />
                        <Button
                          className="mt-2"
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => capture()}
                        >
                          <i
                            className={`edit-image-icon ph-fill ph-camera`}
                            style={{ color: "#efefef", fontSize: 30 }}
                          ></i>
                          Capturar
                        </Button>
                      </Box>
                    </Modal>

                    {pessoa.avatar && (
                      <IconButton
                        size="medium"
                        aria-label="Remover imagem"
                        onClick={() => handleRemoveAvatar()}
                      >
                        <i
                          className={`edit-image-icon ph-fill ph-trash`}
                          style={{ color: "#efefef", fontSize: 30 }}
                        ></i>
                      </IconButton>
                    )}
                  </div>
                ) : (
                  ""
                )}
                {isCropping && (
                  <ImageCropper
                    image={imageAvatar}
                    open={isCropping}
                    onSave={(croppedFile) => onSaveImageClopper(croppedFile)}
                    onHide={() => onHideImageCropper()}
                  />
                )}
              </Grid>
            </Grid>
            <Grid className="grid-container">
              <Grid container spacing={2}>
                <Grid item xs={12} className="mt-4">
                  <span className="fw-bold fw-bolder">OBSERVAÇÃO</span>
                </Grid>
                <Grid item xs={12}>
                  <TextareaAutosize
                    id="observacao"
                    label="Observacao"
                    name="observacao"
                    variant="outlined"
                    maxLength={255}
                    className="textRolagem bg-transparent"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Button id="form-button" type="submit" className="d-none" />
          </Form>
        )}
        <div>
          <hr className="mt-5" />
          <div className="mt-4">
            <Enderecos
              permisoes={permisoes}
              id={id}
              enderecosLista={enderecosLista}
              setEnderecosLista={setEnderecosLista}
              openEndereco={openEndereco}
              setOpenEndereco={setOpenEndereco}
              inputErros={inputErrosEnderecos}
              cacheDto={cacheDto}
              setCacheDto={setCacheDto}
            />
          </div>
          <hr className="my-4" />
          <Grid item xs={12} className="mb-4">
            <span className="fw-bold fw-bolder">TIPOS DE PESSOA</span>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="tiposPessoas"
              name="tiposPessoas"
              options={listaTiposPessoa}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) =>
                option.descricao ? option.descricao : ""
              }
              onChange={(_, newValue) => {
                setPessoa({
                  ...pessoa,
                  tiposPessoas: newValue,
                });
              }}
              value={getMultipleAutocompleteValues(
                pessoa?.tiposPessoas,
                listaTiposPessoa
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Tipos de Pessoa"
                  required
                  error={inputErros.get("tiposPessoas")}
                />
              )}
            />
          </Grid>
          <div className="mt-4">
            <TipoPessoas
              id={id}
              pessoa={pessoa}
              abaValue={abaValue}
              setAbaValue={setAbaValue}
              clienteFormRef={clienteFormRef}
              cliente={cliente}
              setCliente={setCliente}
              fornecedorFormRef={fornecedorFormRef}
              fornecedor={fornecedor}
              setFornecedor={setFornecedor}
              transportadoraFormRef={transportadoraFormRef}
              transportadora={transportadora}
              setTransportadora={setTransportadora}
              colaboradorFormRef={colaboradorFormRef}
              colaborador={colaborador}
              setColaborador={setColaborador}
              prestadorServicoFormRef={prestadorServicoFormRef}
              prestadorServico={prestadorServico}
              setPrestadorServico={setPrestadorServico}
              inputErros={inputErros}
              cacheDto={cacheDto}
              setCacheDto={setCacheDto}
            />
          </div>
        </div>
      </AddLayout>
    </>
  );
};

export default AddPessoas;
