const formatPrice = (valor, cifra = true) => {
  if (valor !== undefined && valor !== null) {
    return cifra
      ? valor.toLocaleString("pt-br", { style: "currency", currency: "BRL" })
      : valor.toLocaleString("pt-br", {
          style: "decimal",
          minimumFractionDigits: 2,
        });
  }
  return valor;
};

export default formatPrice;
