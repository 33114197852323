import { useEffect, useState } from "react";
import {
  CardHeader,
  Dialog,
  Box,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  Autocomplete,
  TextField,
  IconButton,
  Tooltip,
  Grid,
} from "../../../../../components";
import {
  FormatNfe,
  CadastrarFornecedorXml,
  FormatXmlProdutos,
  CadastrarTransportadoraXml,
} from "../../entities";
import {
  cadastroProdutosDto,
  getPagamentoDuplicataXml,
  parseXml,
} from "../../nf-entrada-xml";
import ImportarXmlStepper from "./importar-xml-stepper.component";
import {
  CfopService,
  EnderecoService,
  GrupoProdutosService,
  GruposTributarioService,
  MeioPagamentoService,
  NcmService,
  NfEntradaService,
  OrigemMercadoriaService,
  PessoaService,
  ProdutosService,
  UnidadeComercialService,
  notification,
} from "../../../../../services";
import { FormatCfopHelper, InputErros } from "../../../../../helpers";
import {
  convertXmlToJson,
  formatDate,
  responseErros,
} from "../../../../../utils";
import { useImportContext } from "../../../../../contexts/import.context";
import shortid from "shortid";
import { addDays } from "date-fns";

const cfopHelper = new FormatCfopHelper();
const pessoaService = new PessoaService();
const produtosService = new ProdutosService();
const meioPagamentoService = new MeioPagamentoService();
const gruposTributarioService = new GruposTributarioService();
const grupoProdutosService = new GrupoProdutosService();
const ncmService = new NcmService();
const cfopService = new CfopService();
const enderecoService = new EnderecoService();
const unidadeComercialService = new UnidadeComercialService();
const origemMercadoriaService = new OrigemMercadoriaService();
const nfEntradaService = new NfEntradaService();

const ImportarXmlDialog = ({
  openImportarXMLDialog,
  setOpenImportarXMLDialog,
  loading,
  setLoading,
}) => {
  const { nfEntradaDto, setNfEntradaDto } = useImportContext();
  const [activeStep, setActiveStep] = useState(-1);
  const [arquivoSelecionado, setArquivoSelecionado] = useState(false);
  const [xmlFile, setXmlFile] = useState(null);
  const [dadosXML, setDadosXML] = useState(null);
  const [fornecedor, setFornecedor] = useState({});
  const [enderecoFornecedor, setEnderecoFornecedor] = useState({});
  const [temTransportadora, setTemTransportadora] = useState(false);
  const [transportadora, setTransportadora] = useState({});
  const [listaUnidadesComerciais, setListaUnidadesComerciais] = useState([]);
  const [listaEstados, setListaEstados] = useState([]);
  const [pagamentos, setPagamentos] = useState([]);
  const [origensMercadorias, setOrigensMercadorias] = useState([]);
  const [gruposTributarios, setGruposTributarios] = useState([]);
  const [gruposProdutos, setGruposProdutos] = useState([]);
  const [grupoProduto, setGrupoProduto] = useState(null);
  const [grupoTributario, setGrupoTributario] = useState(null);
  const [temProdutosNaoCadastrados, setTemProdutosNaoCadastrados] =
    useState(false);
  const [vincularProdutoExistente, setVincularProdutoExistente] =
    useState(false);
  const [converterEntrada, setConverterEntrada] = useState(false);
  const [listaProdutos, setListaProdutos] = useState([]);
  const [produtoSelecionado, setProdutoSelecionado] = useState([]);
  const [produtosConvertidos, setProdutosConvertidos] = useState([]);
  const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
  const [steps, setSteps] = useState(["Fornecedor", "Produtos"]);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  const colunasProdutos = [
    {
      field: "produto",
      headerName: "Produto",
      flex: 350,
    },
    {
      field: "codigoBarra",
      headerName: "Cod. de Barra",
      flex: 180,
    },
    {
      field: "ncmCodigo",
      headerName: "NCM",
      flex: 150,
      editable: true,
      renderCell: (params) => {
        const indiceItem = params.api.getRowIndexRelativeToVisibleRows(
          params.row.id
        );
        return (
          <div key={params.row.id}>
            <TextField
              id="ncmCodigo"
              name="ncmCodigo"
              type="text"
              label="NCM"
              required
              variant="standard"
              value={params.value}
              fullWidth
              error={
                inputErros.get(`itens[${indiceItem}].ncmCodigo`) ||
                inputErros.get(`itens[${indiceItem}].ncmId`)
              }
              InputProps={{
                endAdornment: (
                  <i className="ph-fill ph-pencil" style={{ fontSize: 20 }}></i>
                ),
              }}
            />
          </div>
        );
      },
    },
    {
      field: "cfop",
      headerName: "CFOP",
      flex: 100,
      editable: true,
      renderCell: (params) => {
        const indiceItem = params.api.getRowIndexRelativeToVisibleRows(
          params.row.id
        );
        return (
          <div key={params.row.id}>
            <TextField
              id="cfop"
              name="cfop"
              type="text"
              label="CFOP"
              required
              variant="standard"
              value={params.value}
              fullWidth
              error={
                inputErros.get(`itens[${indiceItem}].cfop`) ||
                inputErros.get(`itens[${indiceItem}].cfopId`)
              }
              InputProps={{
                endAdornment: (
                  <i className="ph-fill ph-pencil" style={{ fontSize: 20 }}></i>
                ),
              }}
            />
          </div>
        );
      },
    },
    {
      field: "grupoProdutoId",
      headerName: "Grupo de Produto",
      flex: 190,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const indiceItem = params.api.getRowIndexRelativeToVisibleRows(
          params.row.id
        );
        return (
          <div className="w-100 pt-1">
            <FormControl fullWidth variant="standard">
              <Autocomplete
                id="grupoProdutoId"
                name="grupoProdutoId"
                size="small"
                options={gruposProdutos}
                noOptionsText="Sem opções"
                autoHighlight
                getOptionLabel={(option) =>
                  option?.descricao ? option.descricao : ""
                }
                disabled={params.row?.produtoCadastrado}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Grupo de Produto"
                    required
                    variant="standard"
                    error={inputErros.get(
                      `itens[${indiceItem}].grupoProdutoId`
                    )}
                    disabled={params.row?.produtoCadastrado}
                  />
                )}
                onChange={(_, value) =>
                  handleChangeAutocomplete(
                    params.row.id,
                    "grupoProdutoId",
                    value
                  )
                }
                value={
                  gruposProdutos.find(
                    (grupo) =>
                      grupo.id === getProdutoXml(params.row.id)?.grupoProdutoId
                  ) ?? grupoProduto
                }
              />
            </FormControl>
          </div>
        );
      },
    },
    {
      field: "grupoTributario",
      headerName: "Grupo Tributário",
      flex: 190,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const indiceItem = params.api.getRowIndexRelativeToVisibleRows(
          params.row.id
        );
        return (
          <div className="w-100 pt-1">
            <FormControl fullWidth variant="standard">
              <Autocomplete
                id="grupoTributacaoId"
                size="small"
                name="grupoTributacaoId"
                options={gruposTributarios}
                noOptionsText="Sem opções"
                autoHighlight
                getOptionLabel={(option) => (option?.nome ? option.nome : "")}
                disabled={params.row?.produtoCadastrado}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Grupo Tributário"
                    variant="standard"
                    error={inputErros.get(
                      `itens[${indiceItem}].grupoTributacaoId`
                    )}
                    disabled={params.row?.produtoCadastrado}
                    required
                  />
                )}
                onChange={(_, value) =>
                  handleChangeAutocomplete(
                    params.row.id,
                    "grupoTributacaoId",
                    value
                  )
                }
                value={
                  gruposTributarios.find(
                    (grupo) =>
                      grupo.id ===
                      getProdutoXml(params.row.id)?.grupoTributacaoId
                  ) ?? grupoTributario
                }
              />
            </FormControl>
          </div>
        );
      },
    },
    {
      field: "unidadeComercialVendaId",
      headerName: "Unid. Venda",
      flex: 150,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const indiceItem = params.api.getRowIndexRelativeToVisibleRows(
          params.row.id
        );
        return (
          <div className="w-100 pt-1">
            <FormControl fullWidth variant="standard">
              <Autocomplete
                id="unidadeComercialVendaId"
                name="unidadeComercialVendaId"
                size="small"
                options={listaUnidadesComerciais}
                noOptionsText="Sem opções"
                autoHighlight
                disableClearable
                getOptionLabel={(option) =>
                  option?.descricao ? option.descricao : ""
                }
                disabled={params.row?.produtoCadastrado}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Unid. Venda"
                    variant="standard"
                    error={inputErros.get(
                      `itens[${indiceItem}].unidadeComercialVendaId`
                    )}
                    disabled={params.row?.produtoCadastrado}
                  />
                )}
                onChange={(_, value) =>
                  handleChangeAutocomplete(
                    params.row.id,
                    "unidadeComercialVendaId",
                    value
                  )
                }
                value={listaUnidadesComerciais.find(
                  (unidade) =>
                    unidade.id ===
                    getProdutoXml(params.row.id)?.unidadeComercialVendaId
                )}
              />
            </FormControl>
          </div>
        );
      },
    },
    {
      field: "acoes",
      sortable: false,
      flex: 120,
      headerAlign: "center",
      disableClickEventBubbling: true,
      renderHeader: () => {
        return (
          <div>
            <i className="ph-fill ph-note-pencil" style={{ fontSize: 20 }}></i>
          </div>
        );
      },
      renderCell: (params) => {
        const indiceItem = params.api.getRowIndexRelativeToVisibleRows(
          params.row.id
        );
        return (
          <Grid container justifyContent="center">
            {params.row?.produtoCadastrado && (
              <>
                <Tooltip
                  title={
                    params.row?.produtoCadastrado
                      ? "Produto Cadastrado!"
                      : "Cadastre ou vincule um produto"
                  }
                  placement="top"
                >
                  <IconButton disabled>
                    <i
                      className="ph-fill ph-check-fat"
                      style={{
                        fontSize: 20,
                        color: "#4FBB53",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Trocar Item" placement="top">
                  <IconButton
                    onClick={() =>
                      handleVincularProduto(params.row, "CONVERTER", indiceItem)
                    }
                  >
                    <i
                      className="ph-fill ph-git-diff"
                      style={{
                        fontSize: 21,
                        color: `${
                          params.row?.produtoConvertido ? "#2196F3" : "#494C62"
                        }`,
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>
              </>
            )}
            {!params.row.produtoCadastrado &&
              !nfEntradaDto?.usoConsumoImportado && (
                <>
                  <Tooltip title="Vincular Produto" placement="top">
                    <IconButton
                      onClick={() =>
                        handleVincularProduto(params.row, "VINCULAR")
                      }
                    >
                      <i
                        className="ph-bold ph-link"
                        style={{ fontSize: 20 }}
                      ></i>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Trocar Item" placement="top">
                    <IconButton
                      onClick={() =>
                        handleVincularProduto(
                          params.row,
                          "CONVERTER",
                          indiceItem
                        )
                      }
                    >
                      <i
                        className="ph-fill ph-git-diff"
                        style={{
                          fontSize: 21,
                          color: `${
                            params.row?.produtoConvertido
                              ? "#2196F3"
                              : "#494C62"
                          }`,
                        }}
                      ></i>
                    </IconButton>
                  </Tooltip>
                </>
              )}
          </Grid>
        );
      },
    },
  ];

  const initXmlClose = () => {
    setXmlFile(null);
    setSteps(["Fornecedor", "Produtos"]);
    setEnderecoFornecedor({});
    setGrupoTributario(null);
    setGrupoProduto(null);
    setArquivoSelecionado(false);
    setOpenImportarXMLDialog(false);
    setLoading(false);
    setNfEntradaDto({
      nfEntradaItens: [],
      nfEntradaTributos: {},
    });
    setActiveStep(-1);
  };

  const getProdutoXml = (id) => {
    return dadosXML.itens?.length
      ? dadosXML.itens.find((item) => item.id === id)
      : [];
  };

  const handleChangeAutocomplete = (id, name, value) => {
    const index = dadosXML.itens?.findIndex((item) => item.id === id);
    setDadosXML((prevState) => ({
      ...prevState,
      itens: dadosXML.itens.map((item, _index) =>
        _index === index
          ? {
              ...item,
              [name]: value ? value.id : undefined,
            }
          : item
      ),
    }));
  };

  useEffect(() => {
    if (openImportarXMLDialog) {
      setGruposIdsProdutos();
    }
  }, [grupoProduto, grupoTributario]);

  const setGruposIdsProdutos = () => {
    if (!dadosXML?.itens) return;
    const grupoTributacaoId = grupoTributario?.id;
    const grupoProdutoId = grupoProduto?.id;

    setDadosXML((prevState) => ({
      ...prevState,
      itens: prevState.itens.map((item) => ({
        ...item,
        grupoTributacaoId:
          item.produtoCadastrado || item.grupoTributacaoId
            ? item.grupoTributacaoId
            : grupoTributacaoId,
        grupoProdutoId:
          item.produtoCadastrado || item.grupoProdutoId
            ? item.grupoProdutoId
            : grupoProdutoId,
      })),
    }));
  };

  const handleVincularProduto = (produto, tipo, produtoIndice) => {
    setProdutoSelecionado(produto);
    if (tipo === "CONVERTER") {
      if (produtoIndice !== null && produto?.produtoConvertido) {
        desfazerConversao(produtoIndice);
      }
      setConverterEntrada(true);
    }
    if (tipo === "VINCULAR") {
      setVincularProdutoExistente(true);
    }
  };

  const desfazerConversao = (produtoIndice) => {
    const produtoConvertido = produtosConvertidos.find(
      (produto) => produto.produtoIndice === produtoIndice
    );
    if (produtoConvertido) {
      const produtosXmlTemp = [
        ...dadosXML.itens.map((item, _index) => {
          if (_index === produtoIndice) {
            return produtoConvertido;
          } else {
            return item;
          }
        }),
      ];
      setDadosXML((prevState) => ({
        ...prevState,
        itens: produtosXmlTemp,
      }));
      setProdutosConvertidos(
        produtosConvertidos.filter(
          (produto) => produto.id !== produtoConvertido.id
        )
      );
    }
  };

  const buscarProdutos = (filtros) => {
    const filtro = {
      ...filtros,
      ativado: true,
      limite: 20,
    };
    produtosService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setLoadingAutocomplete(false);
        setListaProdutos(result.data.rows);
      } else {
        setLoadingAutocomplete(false);
        responseErros(result);
      }
    });
  };

  useEffect(() => {
    if (openImportarXMLDialog) {
      buscarGruposTributario();
      buscarGruposProdutos();
      buscarUnidadesComerciais();
      buscarOrigensMercadoria();
      buscarEstados();
    }
    setActiveStep(-1);
  }, [openImportarXMLDialog]);

  const buscarFornecedores = async (
    fornecedor,
    dadosXml,
    validarNota = false
  ) => {
    const filtro = {
      cnpjCpf: fornecedor.cnpjCpf,
    };
    const result = await pessoaService.getAll(filtro);
    if (!result.isAxiosError) {
      if (result.data.rows.length) {
        if (validarNota) {
          await buscarNfEntradaExistente(result.data.rows[0]?.id, dadosXml);
        }
        setFornecedor(result.data.rows[0]);
        return result.data.rows[0].id;
      } else {
        setFornecedor(fornecedor);
      }
    } else responseErros(result);
  };

  const buscarNfEntradaExistente = async (fornecedorId, dadosXml) => {
    if (dadosXml?.numeroNota && dadosXml?.numeroNota) {
      setLoading(true);
      const result = await nfEntradaService.getAllFiltroAvancado({
        fornecedorId,
        numeroNota: dadosXml.numeroNota,
        serie: dadosXml.serie,
        restritiva: true,
      });
      if (!result.isAxiosError) {
        if (result.data.rows.length) {
          initXmlClose();
          return notification.alertaGenericos(
            "Uma nota de entrada com mesmo número e série desse fornecedor já foi lançada anteriormente."
          );
        }
      } else responseErros(result);
      setLoading(false);
    }
  };

  const buscarTransportadora = async (transportadora) => {
    if (!transportadora) return;
    const filtro = {
      cnpjCpf: transportadora.cnpjCpf,
    };
    const result = await pessoaService.getAll(filtro);
    if (!result.isAxiosError) {
      if (result.data.rows.length) {
        setTransportadora({
          ...result.data.rows[0],
          transportadora: result.data.rows[0].transportadoras[0],
        });
      } else {
        setTransportadora(transportadora);
      }
    } else responseErros(result);
  };

  const buscarEndereco = async (endereco) => {
    if (endereco?.cep) {
      const result = await enderecoService.getByCep(endereco.cep);
      if (!result.isAxiosError) {
        if (result.data) {
          setEnderecoFornecedor({
            ...endereco,
            cep: parseFloat(result.data.cep),
            cidadeId: result.data.id,
            cidade: result.data.nome,
            estado: result.data.estado.sigla,
          });
        }
      } else {
        responseErros(result);
      }
    }
  };

  const buscarNcms = async (codigo) => {
    const filtros = {
      codigo,
      nonPaginated: true,
    };
    if (codigo.length) {
      const result = await ncmService.getAllAvancado(filtros);
      if (!result.isAxiosError) {
        return result.data;
      } else {
        responseErros(result);
      }
    }
  };

  const buscarCfops = async (codigo) => {
    const filtros = {
      codigo,
      nonPaginated: true,
    };
    if (codigo.length) {
      const result = await cfopService.getAllAvancado(filtros);
      if (!result.isAxiosError) {
        return result.data;
      } else {
        responseErros(result);
      }
    }
  };

  const buscarGruposTributario = () => {
    const filter = {
      ativado: true,
      nonPaginated: true,
    };
    gruposTributarioService.getAll(filter).then((res) => {
      if (!res.isAxiosError) {
        if (res.data) {
          setGruposTributarios(res.data);
        }
      } else {
        responseErros(res);
      }
    });
  };

  const buscarGruposProdutos = () => {
    const filter = {
      nonPaginated: true,
      ativado: true,
    };
    grupoProdutosService.getAll(filter).then((res) => {
      if (!res.isAxiosError) {
        setGruposProdutos(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarUnidadesComerciais = () => {
    unidadeComercialService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setListaUnidadesComerciais(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarEstados = () => {
    enderecoService.getEstados().then((res) => {
      if (!res.isAxiosError) {
        setListaEstados(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarOrigensMercadoria = () => {
    origemMercadoriaService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setOrigensMercadorias(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const formatarProdutos = async (nfItens, fornecedorId) => {
    const codigosProdutos = new Set();
    const cfopsProdutos = new Set();
    nfItens.forEach((item) => {
      codigosProdutos.add(item.prod?.NCM);
      cfopsProdutos.add(cfopHelper.contraPartida(item.prod?.CFOP));
    });
    const [listaNcm, listaCfops, produtosFornecedores] = await Promise.all([
      buscarNcms(Array.from(codigosProdutos)),
      buscarCfops(Array.from(cfopsProdutos)),
      buscarProdutosFornecedores(fornecedorId),
    ]);
    const itensFormatados = nfItens.map((item, index) => {
      const produto = new FormatXmlProdutos(
        { ...item.prod, ...item.imposto, nItem: item.nItem },
        fornecedorId,
        listaNcm,
        listaCfops,
        listaUnidadesComerciais,
        origensMercadorias,
        nfEntradaDto
      );
      const itemEncontrado =
        produtosFornecedores?.length &&
        produtosFornecedores.find(
          (produtoFornecedor) =>
            produtoFornecedor?.produto?.descricao == produto.descricao &&
            produtoFornecedor?.produto?.codigoBarra == produto.codigoBarra &&
            produtoFornecedor?.codigoProduto == produto.codigoProduto
        );
      if (itemEncontrado) {
        return cadastroProdutosDto(
          {
            ...produto,
            ...itemEncontrado.produto,
            precoCompra: produto.precoCompra,
            codigoProduto: itemEncontrado.codigoProduto,
            produtoId: itemEncontrado.produto.id,
            aliquotaMva: produto?.aliquotaMva,
            produtoCadastrado: true,
          },
          index
        );
      } else {
        setTemProdutosNaoCadastrados(true);
        return cadastroProdutosDto(produto, index);
      }
    });
    setDadosXML((prevState) => ({
      ...prevState,
      itens: itensFormatados,
    }));
  };

  const buscarProdutosFornecedores = async (fornecedorId) => {
    if (!fornecedorId) return false;
    const result = await produtosService.getAllProdutosFornecedores({
      fornecedorId,
      nonPaginated: true,
    });
    if (!result.isAxiosError) {
      return result.data;
    } else {
      responseErros(result);
    }
  };

  const buscarMeiosPagamento = async (pagamentosDuplicatas) => {
    if (!pagamentosDuplicatas.length) return;
    const nfePagamento = Array.from(
      new Set(pagamentosDuplicatas.map(({ tPag }) => tPag))
    );
    const res = await meioPagamentoService.getAllFiltroAvancado({
      nfePagamento,
      nonPaginated: true,
    });
    if (!res.isAxiosError) {
      if (res.data.length) {
        const pagamentosDup = pagamentosDuplicatas
          .map((pagamentoDup) => {
            const meioPag = res.data.find(
              (meio) => meio.nfePagamento === pagamentoDup.tPag
            );
            if (meioPag) {
              return {
                id: shortid.generate(),
                numeroTitulo: pagamentoDup.nDup,
                dataVencimento: formatDate.toSend(
                  addDays(new Date(pagamentoDup.dVenc), 1)
                ),
                meioPagamentoId: meioPag.id,
                valorTitulo: parseFloat(pagamentoDup.vDup),
              };
            }
            return null;
          })
          .filter((pagamento) => pagamento !== null);
        setPagamentos(pagamentosDup);
      }
    } else {
      responseErros(res);
    }
  };

  const formatImpostos = (impostos) => {
    return Object.keys(impostos).reduce((impostosFormatados, imposto) => {
      const subImposto =
        typeof impostos[imposto] === "object"
          ? Object.entries(impostos[imposto]).reduce(
              (acc, [chave, valor]) => ({
                ...acc,
                ...(typeof valor === "object" ? valor : { [chave]: valor }),
              }),
              {}
            )
          : impostos[imposto];
      return { ...impostosFormatados, [imposto]: subImposto };
    }, {});
  };

  const extrairDadosNFe = (xmlData) => {
    const {
      nfeProc: {
        NFe,
        protNFe: { infProt },
      },
    } = convertXmlToJson(xmlData);
    if (!NFe) {
      initXmlClose();
      return notification.alertaGenericos(
        "Por favor, inserir XML referente a NF-e"
      );
    }
    const objetoFormatDto = {
      fornecedor: NFe.infNFe?.emit,
      identificacao: NFe.infNFe?.ide,
      protocolo: infProt,
      transporte: NFe.infNFe?.transp,
      itens: Array.isArray(NFe.infNFe?.det)
        ? NFe.infNFe?.det.map((item) => {
            const imposto = formatImpostos(item.imposto);
            const itemFormatado = {
              ...item,
              imposto,
            };
            return itemFormatado;
          })
        : [
            {
              ...NFe.infNFe.det,
              imposto: formatImpostos(NFe.infNFe.det?.imposto),
            },
          ],
      totais: NFe.infNFe.total.ICMSTot,
      pagamentos: NFe.infNFe?.pag.detPag,
      duplicatas: NFe.infNFe?.cobr?.dup,
      informacoes: NFe.infNFe?.infAdic,
    };
    if (NFe.infNFe?.pag.detPag) {
      if (!Array.isArray(NFe.infNFe?.pag.detPag)) {
        objetoFormatDto.pagamentos = [NFe.infNFe?.pag.detPag];
      }
    }
    if (NFe.infNFe?.cobr?.dup) {
      if (!Array.isArray(NFe.infNFe?.cobr?.dup)) {
        objetoFormatDto.duplicatas = [NFe.infNFe?.cobr?.dup];
      }
    }
    return objetoFormatDto;
  };

  const formatNFeData = async (data, listaEstados) => {
    let stepsTemp = [...steps];
    const dtoNfe = new FormatNfe({
      ...data.identificacao,
      ...data.protocolo,
      ...data.transporte,
      ...data.totais,
      informacoes: data.informacoes,
      itens: data.itens,
    });
    setDadosXML(dtoNfe);
    const dtoFornecedor = new CadastrarFornecedorXml(data.fornecedor);
    const dtoTransportadora = new CadastrarTransportadoraXml(
      data.transporte?.transporta,
      listaEstados
    );
    if (data.transporte?.transporta) {
      stepsTemp.splice(1, 0, "Transportadora");
      setTemTransportadora(true);
    }
    const pagamentosDuplicatas = getPagamentoDuplicataXml(
      data.pagamentos,
      data.duplicatas,
      data.identificacao.dhEmi
    );
    await Promise.all([
      buscarTransportadora(dtoTransportadora),
      buscarMeiosPagamento(pagamentosDuplicatas),
      buscarEndereco(dtoFornecedor.enderecos[0]),
    ]);
    const fornecedorId = await buscarFornecedores(dtoFornecedor, dtoNfe, true);
    if (fornecedorId) {
      await formatarProdutos(dtoNfe.itens, fornecedorId);
    }
    setLoading(false);
    setActiveStep(activeStep + 1);
    setSteps(stepsTemp);
  };

  const handleFileRead = async (e) => {
    const content = e.target.result;
    const xmlData = parseXml(content.replace(/>\s+</g, "><").trim());
    const dadosExtraidos = extrairDadosNFe(xmlData);
    await formatNFeData(dadosExtraidos, listaEstados);
  };

  const buscarXmlData = () => {
    const reader = new FileReader();
    reader.onloadend = handleFileRead;
    reader.readAsText(xmlFile);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullScreen={activeStep !== -1}
      fullWidth
      open={openImportarXMLDialog}
      onClose={initXmlClose}
    >
      <CardHeader title="Importar XML" className="m-2" />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        {activeStep > -1 ? (
          <Stepper activeStep={activeStep} sx={{ width: "50%", m: "1.4em" }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        ) : (
          <></>
        )}
      </Box>
      <ImportarXmlStepper
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        setOpenImportarXMLDialog={setOpenImportarXMLDialog}
        arquivoSelecionado={arquivoSelecionado}
        setArquivoSelecionado={setArquivoSelecionado}
        dadosXML={dadosXML}
        setDadosXML={setDadosXML}
        xmlFile={xmlFile}
        setXmlFile={setXmlFile}
        buscarXmlData={buscarXmlData}
        buscarTransportadora={buscarTransportadora}
        buscarProdutos={buscarProdutos}
        listaProdutos={listaProdutos}
        setListaProdutos={setListaProdutos}
        produtoSelecionado={produtoSelecionado}
        vincularProdutoExistente={vincularProdutoExistente}
        setVincularProdutoExistente={setVincularProdutoExistente}
        converterEntrada={converterEntrada}
        setConverterEntrada={setConverterEntrada}
        gruposTributarios={gruposTributarios}
        gruposProdutos={gruposProdutos}
        grupoTributario={grupoTributario}
        setGrupoTributario={setGrupoTributario}
        grupoProduto={grupoProduto}
        setGrupoProduto={setGrupoProduto}
        fornecedor={fornecedor}
        enderecoFornecedor={enderecoFornecedor}
        transportadora={transportadora}
        colunasProdutos={colunasProdutos}
        temProdutosNaoCadastrados={temProdutosNaoCadastrados}
        setTemProdutosNaoCadastrados={setTemProdutosNaoCadastrados}
        loading={loading}
        setLoading={setLoading}
        loadingAutocomplete={loadingAutocomplete}
        setLoadingAutocomplete={setLoadingAutocomplete}
        temTransportadora={temTransportadora}
        pagamentos={pagamentos}
        inputErros={inputErros}
        setFornecedor={setFornecedor}
        formatarProdutos={formatarProdutos}
        handleCloseDialog={initXmlClose}
        produtosConvertidos={produtosConvertidos}
        setProdutosConvertidos={setProdutosConvertidos}
      />
    </Dialog>
  );
};

export default ImportarXmlDialog;
