import { useEffect, useState } from "react";
import { Grid, TextField, Select, MenuItem } from "../../../../components";
import { EtiquetasService, MarcasService } from "../../../../services";
import { responseErros } from "../../../../utils";
import { value } from "../../../../helpers";
import { Autocomplete } from "@mui/material";

const FormFiltroAvancado = ({
  valueInput,
  onChangeInput,
  onChangeMultiple,
}) => {
  const etiquetasService = new EtiquetasService();
  const marcasService = new MarcasService();
  const [etiquetas, setEtiquetas] = useState([]);
  const [marcas, setMarcas] = useState([]);

  useEffect(() => {
    buscarEtiquetas();
    buscarMarcas();
  }, []);

  const buscarEtiquetas = async function () {
    const filtros = {
      nonPaginated: true,
      ativado: true,
      tipo: "PRODUTO",
    };
    const result = await etiquetasService.getAll(filtros);
    if (!result.isAxiosError) {
      setEtiquetas(result.data);
    } else {
      responseErros(result);
    }
  };

  const buscarMarcas = async function (filter) {
    const result = await marcasService.getAll(filter);
    if (!result.isAxiosError) {
      setMarcas(result.data.rows);
    } else {
      responseErros(result);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="codigo"
          name="codigo"
          label="Código"
          variant="outlined"
          fullWidth
          onChange={(event) => onChangeInput(event)}
          value={valueInput("codigo")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="codigoBarra"
          name="codigoBarra"
          label="Código Barras"
          variant="outlined"
          fullWidth
          onChange={(event) => onChangeInput(event)}
          value={valueInput("codigoBarra")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="descricao"
          name="descricao"
          label="Descrição"
          variant="outlined"
          fullWidth
          onChange={(event) => onChangeInput(event)}
          value={valueInput("descricao")}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          id="tipo"
          name="tipo"
          variant="outlined"
          label="Tipo"
          fullWidth
          onChange={(event) => onChangeInput(event)}
        >
          <MenuItem value="simples">Simples</MenuItem>
          <MenuItem value="grade">Grade</MenuItem>
          <MenuItem value="kit">Kit</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          id="etiquetasId"
          name="etiquetasId"
          options={etiquetas}
          noOptionsText="Sem opções"
          autoHighlight
          filterSelectedOptions
          getOptionLabel={(option) => {
            return (
              <div>
                <i
                  className="ph-fill ph-bookmark-simple"
                  style={{ color: `${option?.cor}`, marginRight: "10px" }}
                ></i>
                {option?.descricao}
              </div>
            );
          }}
          onChange={(_, newValue) => onChangeMultiple(newValue, "etiquetasId")}
          value={value.multiple(etiquetas, valueInput("etiquetasId"))}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Etiquetas" />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          id="marcasId"
          name="marcasId"
          options={marcas}
          noOptionsText="Sem opções"
          autoHighlight
          filterSelectedOptions
          getOptionLabel={(option) => option?.nome || ""}
          onChange={(_, newValue) => onChangeMultiple(newValue, "marcasId")}
          value={value.multiple(marcas, valueInput("marcasId"))}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Marcas" />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
